.popup_container {
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: 1s ease-in-out;
  width: 100%;
  z-index: 999999;
}
.popup_container.active {
  display: flex;
}
.PopupOuter {
  background: #ffffff;
  border-radius: 6px;
  width: 325px;
 
}
.PopupHeading {
  display: flex;
  padding: 12px 25px 12px 20px;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
}
.PopupHeading h1 {
  font-family: "Inter Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
}
.popup_body{
    padding: 12px 20px;
}
.popup_body p{
    font-family: 'Inter Light BETA';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #262626;
}
.cancelBtn {
  background: #ffffff;
  color: #99cbf7;
  border: 1px solid #99cbf7;
  border-radius: 6px;
  width: auto;
  padding: 8px 20px;
}
.confirmBtn {
  background: #3397ef;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  margin-left: 10px;
  width: auto;
  padding: 8px 20px;
}
.popupBtns{
    margin-top: 40px;
    display: flex;
   justify-content: flex-end;
}
.svg{
  cursor: pointer;
}