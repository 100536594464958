.editProfileWrapper{
    padding: 30px;
    max-height: calc(100vh - 175px);
    overflow: auto;
}

.smallNotification{
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--black-5);
    display: block;
}

.profileDetails{
    max-width: 730px;
}

.profileRadioGroup{
    gap: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
}

.profileRadioGroup .feedbackRadioGroup{
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    max-width: inherit;
    display: flex;
}

.profileRadioGroup [type="radio"]:not(:checked) + label{
    padding: 12px 20px;
    min-width: max-content;
    border: 1px solid var(--grey-2);
    border-radius: 10px;
    color: var(--black-5);
    font-size: 16px;
    line-height: 120%;
    transition: 0.4s ease-in-out;
}

.profileRadioGroup [type="radio"]:checked + label{
    padding: 12px 20px;
    min-width: max-content;
    border: 1px solid var(--primary-5);
    border-radius: 10px;
    color: var(--primary-5);
    font-size: 16px;
    line-height: 120%;
    transition: 0.4s ease-in-out;
    background: #E0EFFD;
}

.profileRadioGroup [type="radio"]:checked + label:before,
.profileRadioGroup [type="radio"]:not(:checked) + label:before{
    display: none;
}

.profileRadioGroup [type="radio"]:checked + label:after,
.profileRadioGroup [type="radio"]:not(:checked) + label:after{
    display: none;
}

.editProfileDetailNew{
    padding: 20px;
    border: 1px solid var(--grey-3);
    border-radius: 8px;
}

.fullwidth{
    max-width: 100%;
    width: 100%;
}

.timeWrapper{
    padding: 10px 12px;
    min-width: max-content;
    border: 1px solid var(--grey-2);
    border-radius: 10px;
    color: var(--black-5);
    font-size: 16px;
    line-height: 120%;
    transition: 0.4s ease-in-out;
    min-width: 255px;
}

.editProfileWrap{
    display: block;
    position: relative;
}

.editProfile{
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    width: 35px;
}

.profileImageWrap .editIconProfile{
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}



/* common pop starts */

.popupOuter {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 65px;
    height: calc(100vh - 65px);
    position: fixed;
    padding: 20px;
    z-index: 10;
    background: rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.popupContainer {
    position: relative;
    border-radius: 10px;
    width: 600px;
    margin: auto;
    height: 600px;
    background: var(--white);
    overflow: hidden;
}

.popupOuter.active {
    visibility: visible;
    opacity: 1;
}

.popupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    background: var(--black-1);
    border-bottom: 1px solid var(--grey-3);
}

.popupTitle {
    font-family: 'Inter Medium';
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
}
.popupBody{
    padding: 20px 16px;
    width: 100%;
}

/* common pop ends */