.ToastWrap{
    position: absolute;
    bottom: 30px;
    right: 30px;

    z-index: 999;

    font-size: 16px;
    line-height: 120%;
    color: #404040;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;


    padding: 14px 32px;


    border-radius: 10px;
    max-width: max-content;
    width: 100%;
}

.successToast{
    visibility: hidden;
    border: 2px solid #D7EFDF;
    background: #EBF7EF;
}

.successToast.active {
    visibility: visible;
}

.errorToast{
    border: 2px solid #FACBCB;
    background: #FDE5E5;
    visibility: hidden;
}

.errorToast.active {
    visibility: visible;
    z-index: 9999;
}