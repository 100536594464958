.feedbackData{
    padding: 30px;
    max-height: calc(100vh - 150px);
}

.feedbackData .callBox{
    color: var(--black-1);
    background: var(--grey-3);
}

.FeedbackWrap{
    padding: 30px;
}

.viewFeedbackWrappper .FeedbackWrap .callTypes{
    position: relative;
    padding-bottom: 0;
}

.viewFeedbackWrappper .FeedbackWrap .callTypes::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 2px;
    background: var(--grey-3);
}

.viewFeedbackWrappper .FeedbackWrap .callButton.active::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 2px;
    background: var(--primary-5);
    z-index: 9;
}

.feedbackBoxAll{
    padding: 15px 30px;
    border-radius: 10px;
    background: var(--grey-5);
    max-width: 630px;
}

.feedbackQuestion{
    font-size: 18px;
    line-height: 120%;
    font-family: 'inter regular';
    color: var(--black-1);
}

.ratingScale{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2px;
    position: relative;
}

.ratingScale span{
    display: block;
    position: relative;
    max-width: 85px;
    width: 100%;
    padding-top: 10px;
    text-align: right;
}

.ratingScale span:before{
    content: "";
    min-width: 100%;
    min-height: 6px;
    background: var(--primary-1);
    position: absolute;
    top: 0;
    left: 0;
}

.ratingScale span.active::before{
    background: var(--primary-5);
}

.ratingScale .useless{
    position: absolute;
    text-align: left;
}

.ratingScale .useless::before{
    display: none;
}

.feedbackAnswer{
    font-size: 18px;
    line-height: 150%;
    color: var(--primary-5);
}

.feedbackDetails{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
}

.feedbackBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 5px 10px;
    background: var(--grey-5);
    border: 1px solid var(--grey-2);
    border-radius: 5px;
    font-size: 13px;
    line-height: 120%;
    font-family: 'inter regular';
    /* cursor: pointer; */
}

.viewFeedbackWrappper.conditionFeedWrapper .profileHeader{
    padding: 25px 30px;
}
