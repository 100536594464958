.upload-box-inner{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    background: var(--grey-5);
    min-height: 40px;
    width: 100%;
    border: 1px solid var(--grey-2);
    border-radius: 5px;
}

.upload-box-inner input[type=file]{
    display: none;
}

.upload-btn{
    color: var(--black-2);
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 6px 16px;
    background: var(--white);
    outline: none;
    border: 1px solid var(--grey-4);
    border-radius: 6px;
}

.upload-box-inner label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    color: var(--black-1);
    font-size: 12px !important;
    line-height: 16px;
    cursor: pointer;
}

.upload-box-icons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-8px);
    cursor: pointer;
    visibility: hidden;
    opacity: 1;
    transition: 0.4s ease-in-out;
}

.upload-box-icons.active{
    visibility: visible;
    opacity: 1;
    transition: 0.4s ease-in-out;
}
.uploadBoxOuter .label{
    display: block;
    font-size: 14px;
    position: relative;
    line-height: 120%;
    font-family: 'Inter Regular';
    color: var(--black-2);
    margin-bottom: 5px;
}

.popupOuter {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 65px;
    height: calc(100vh - 65px);
    position: fixed;
    padding: 20px;
    z-index: 10;
    background: rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.popupOuter.active {
    visibility: visible;
    opacity: 1;
}