.routesContainer.LoginWrapper {
    width: 100%;
}

.autheticationWrapper {
    background: rgba(224, 239, 253, 0.5);
    height: calc(100vh - 65px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
}

.autheticationBox {
    max-width: 1000px;
    max-height: 610px;
    height: 100%;
    background: var(--white);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.loginLeft {
    min-width: 420px;
}

.componentLogin,
.componentSignup,
.componentForget,
.componentConfirmPassword {
    padding: 20px;
}

.BlueTag {
    font-size: 14px;
    line-height: 120%;
    font-family: 'Inter Regular';
    color: var(--primary-5);
    background: #E0EFFD;
    padding: 8px 15px;
    border-radius: 35px;
    display: inline-block;
}

.formGroups.checkBoxWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: row-reverse;
    max-width: max-content;
    cursor: pointer;
}

.formGroups.checkBoxWrap input {
    max-width: max-content;
    min-height: max-content;
    cursor: pointer;
}

.formGroups.checkBoxWrap label {
    margin-bottom: 0;
    cursor: pointer;
}

.formGroups.checkBoxWrap label span {
    display: none;
}

.forgetPassword {
    display: block;
    position: relative;
    color: var(--danger-5);
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    cursor: pointer;
}

.formOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.policyWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    color: var(--black-4);
}

.policyText {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    color: var(--black-4);
    cursor: pointer;
}

.forgetWrapper {
    min-height: 350px;
}

.lastWrapper{
    text-align: center;
}

.lastWrapper .policyText{
    color: var(--primary-5);
}

/* Media Query */

/* Mobile phone */
@media (max-width: 767px) {
    .autheticationBox {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
    }

    .loginLeft {
        min-width: 100%;
        width: 100%;
    }

    .autheticationBox {
        padding: 30px 20px;
        border-radius: 10px;
    }

    .BlueTag {
        padding: var(--spacing-12px) var(--spacing-20px);
    }

    .componentLogin {
        padding: 0;
    }

    .autheticationWrapper .loginRight {
        display: none;
    }
}

/* Tab Screen */

@media (min-width: 768px) and (max-width: 1023px) {
    .loginRight {
        width: 100%;
        max-width: 50%;
    }

    .loginLeft {
        width: 100%;
        min-width: 50%;
    }
}

/* Tab Or LapTop */
@media (min-width: 1024px) and (max-width: 1280px) {}