.appContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 65px);
  overflow: auto;
  margin-top: 65px;
}
.routesContainer {
  width: calc(100% - 290px);
}

button{
  cursor: pointer;
}