.CallsWrap{
    padding: 30px;
    max-height: calc(100vh - 151px);
    overflow: auto;
}

.callTypes{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
}

.callButton{
    border: none;
    padding: 0;
    color: var(--black-3);
    max-width: max-content;
    line-height: 100%;
}

.callButton.active{
    color: var(--primary-5);
}

.callersBox{
    padding: 20px;
    border: 1px solid var(--grey-3);
    border-radius: 12px;
    background: var(--grey-5);

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 600px;
}

.callersBox:not(:first-child){
    margin-top: 20px;
}

.callersBoxLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.callerName{
    font-size: 16px;
    line-height: 120%; 
    font-family: 'inter semi bold';
    color: var(--black-1);
}

.callerEmail{
    font-size: 12px;
    line-height: 120%;
    font-family: 'inter regular';
    color: var(--black-5);
}

.callDetails{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.callBox{
    font-size: 14px;
    line-height: 100%;
    font-family: 'inter regular';
    padding: 5px 10px;
    background: transparent;
    border-radius: 12px;
}

.greenCall{
    background: #EBF7EF;
    color: var(--success-6);
}

.blueCall{
    background: #E0EFFD;
    color: var(--primary-5);
}

.scheduleData{
    max-height: calc(100vh - 250px);
    overflow: auto;
}

.tabCall{
    display: none;
}

.tabCall.active{
    display: block; 
}

.callersBoxLeft img {
    width: 70px ;
    height: 70px ;
    object-fit: cover;
    border-radius: 50%;
}