.createFeedbackWrapper{
    background: var(--primary-6);
    height: calc(100vh - 65px);
    width: 100%;

    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.feedbackWrapBox{
    max-width: 1000px;
    max-height: 610px;
    height: 100%;
    background: var(--white);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.feedbackLeft{
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    max-width: 420px;
    height: 100%;
}

.giveFeedbackQuestion{
    font-size: 28px;
    line-height: 150%;
    font-family: 'inter bold';
    color: var(--black-1);
}

.giveFeedbackQuestion span{
    color: var(--primary-5);
    font-family: 'inter bold';
}

.fedbackButtonWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fedbackButtonWrap button{
    max-width: max-content;
    padding: 16px 20px;
    gap: 5px;
}

.fedbackButtonWrap .feedbackBtnBlack{
    color: var(--white);
    background: var(--black-1);
}

.fedbackButtonWrap .feedbackBtnWhite{
    padding-left: 0;
}

.navigationWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
}

.navigationWrap .navigationDot{
    min-width: 6px;
    min-height: 6px;
    background: var(--grey-4);
    border-radius: 50%;
}

.navigationWrap .navigationDot.active{
    background: var(--primary-5);
}


/* custom radio button */

.customRadioWrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.customRadio{
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-1);
}

/* Hide the browser's default radio button */
.customRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom radio button */
.customRadio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border: 1px solid var(--primary-5);
    border-radius: 50%;
}
  
/* On mouse-over, add a grey background color */
.customRadio:hover input ~ .checkmark {
    background-color: var(--white);
  }
  
/* When the radio button is checked, add a blue background */
.customRadio input:checked ~ .checkmark {
    background-color: var(--white);
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.customRadio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.customRadio input:checked ~ .checkmark:after {
    display: block;
  }
  
/* Style the indicator (dot/circle) */
.customRadio .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--primary-5);
}

.customRadio .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--primary-5);
}


.customSlider .rc-slider-rail {
    min-height: 10px;
    /* background: var(--primary-5); */
    border-radius: 2px;
  }

  .customSlider .rc-slider-track {
    min-height: 10px;
    background: var(--primary-5);
  }

.customSlider .rc-slider-handle{
    width: 22px;
    height: 22px;
    opacity: 1;
}

.customSlider .rc-slider-handle:active{
    box-shadow: none;
}

.customSlider .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: none;
}

.rangeCount{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rangeCount span{
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: var(--black-1);
}

.rangeCount span.active{
    color: var(--primary-5);
    font-family: 'inter bold';
}