.menuOuter {
    width: 100%;
    max-width: 290px;
    background: var(--white);
    border-right: 2px solid var(--grey-3);
    height: 100%;
    padding: var(--spacing-28px) var(--spacing-40px);
}

.menuList{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top:var(--spacing-24px)
}

.menuListItem{
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    width: 100%;
    padding: var(--spacing-12px) 0;
    color: var(--grey-1);
    cursor: pointer;
    transition: 0.5s;
}

.menuListItembtn{
    display: flex;
    padding: 20px 22px;
    gap: var(--spacing-8px);
    align-items: center;
    gap: 20px;
    width: 248px;
    height: 60px;
    background: #FAFAFA;
    border-radius: 6px;
    cursor: pointer;
}

.menuListItem:hover,
.menuListItem.active
{
    color: var(--primary-4);
}

.menuListItem:hover svg path,
.menuListItem.active svg path
{
    fill: var(--primary-4);
}

.adminProfile{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
}

.adminImg{
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--grey-3);
}
.adminProfileContent{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4px);
}

.adminProfileContent .name{
    font-size: 20px;
    font-family: 'Inter Bold';
    text-transform: capitalize;
}

.greyText{
    color: var(--grey-1);
}

.adminProfileContent .greyText{
    font-size: 18px;
    cursor: pointer;
}

.menuFlex {
    display: flex;
    height: calc(100% - 32px);
    flex-direction: column;
    justify-content: space-between;
    align-items: center
}

.menuListItem.logoutToggler:hover svg path,
.menuListItem.logoutToggler.active svg path{
    fill: none !important;
    stroke: var(--primary-4);
}
