.profileHeader{
    padding: 10px 15px;
    min-height: 61px;
    border-bottom: 1px solid var(--grey-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.profileHeader button{
    max-width: max-content;
}

.profileMentorWrapper {
    padding: 28px 30px;
    max-height: calc(100vh - 167px);
    overflow: auto;
}

.profileMentor{
    gap: 25px;
}

.profileMentor .mentorDetails{
    margin-left: 0;
    gap: 15px;
}

.profileMentor .mentorDetails .mentorName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.accountDetailsWrap{
    margin-top: var(--spacing-28px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 5px;
    border-bottom: 2px solid var(--grey-3);
    max-width: 630px;
}

.detailsHeading{
    font-size: 16px;
    line-height: 120%;
    color: var(--primary-5);
    position: relative;
    font-family: 'Inter Regular';
}

.detailsHeading::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 2px;
    width: 100%;
    background: var(--primary-5);
}

.profileMentorWrapper .personalDetailOuter .card{
    /* max-width: 300px; */
    max-width: inherit;
    width: calc(50% - 6px);
}

.profileMentorWrapper .heading{
    font-size: 18px;
    line-height: 120%;
    font-family: 'inter semi bold';
}

.profileMentorWrapper .personalDetailOuter{
    max-width: 630px;
}

.divider{
    display: block;
    position: relative;
    border-top: 2px solid var(--grey-3);
}

.profileMentorWrapper .cardsOuter{
    padding: 0;
}

.topBorderMentor{
    border-top: 1px solid var(--grey-3);
    margin-top: 20px !important;
    padding-top: 20px;
}


.mentorProfileOuter{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: flex-start;

}
.mentorProfileContainer{
    width: 100%;
    height: 100%;
    padding: var(--spacing-28px);
    padding-right: 0;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.mentorProfile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-16px);
}

.mentorProfile .mentorImg{
    width: 105px;
    height: 105px;
    border-radius: 50%;
    object-fit: cover;
}

.mentorDetails {
    margin-left: var(--spacing-16px);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20px);
}

.mentorDetails .mentorName{
    font-size: 32px;
    font-family: 'Inter Semi Bold';
}

.mentorDetails .designation{
    font-size: 18px;
    color: var(--black-3);
}

.linkdinBtn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    color: var(--primary-5);
    font-size: 18px;
    margin-top: var(--spacing-8px);
    cursor: pointer;
    font-family: 'Inter Regular';
}

.cardsOuter{
    margin-top: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
}

.card{
    width: 100%;
    padding: var(--spacing-20px);
    border-radius: 10px;
    border: 1px solid var(--grey-3);
    background: var(--grey-5);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--spacing-12px);
}



.cardDetail{
    width: 90%;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--spacing-12px); */
}

.cardDetail .changePass {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.changePass span {
    font-size: 12px;
    color: var(--primary-5);
    cursor: pointer;
}

.card .cardImg{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--grey-3);
}




[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--grey-1);
    width: 100%;
}
[type="radio"]:checked + label{
    color: var(--black-1);
}

.option:focus-within{
    border-color:var(--primary-4) ;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    border: 2px solid var(--black-5);
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:before{
    border-color: var(--black-1);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--black-1);
    position: absolute;
    top: 5px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.personalDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-12px);
}

.personalDetailContainer .card{
    width: 48%;
    padding: var(--spacing-12px);
    justify-content: flex-start;
}

.personalDetailContainer .card .heading{
    font-size: 13px;
    color: var(--black-5);
}

.personalDetailContainer .card .content{
    font-size: 14px;
    font-family: 'Inter Medium';
    color: var(--black-3);
}

.personalDetailContainer .cardsOuter{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing-12px);
    margin: 0;
}

.personalDetailOuter:not(:first-child){
    margin-top: var(--spacing-24px);
}

.fullCard{
    max-width: 100% !important;
    width: 100% !important;
}